// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserCloudIaasServerProject_Details_RequiredProductsScss from "./UserCloudIaasServerProject_Details_RequiredProducts.scss";
import * as UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription from "./UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserCloudIaasServerProject_Details_RequiredProductsScss;

function UserCloudIaasServerProject_Details_RequiredProducts_Volume(props) {
  var volume = props.volume;
  var iops = volume.maxIops;
  var match = volume.throughput;
  var match$1 = volume.throughputUnit;
  var description = volume.description;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Volume #" + String(props.index + 1 | 0),
                      className: css.productName
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Disk/Storage Type",
                                    value: volume.diskType
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Disk/Storage Size",
                                    value: volume.diskSize
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Max IOPS",
                                    value: iops !== undefined ? String(iops) + " GB" : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Throughput",
                                    value: match !== undefined && match$1 !== undefined ? String(match) + (" " + match$1) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Description",
                                    value: description !== undefined ? description : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            })
                      ],
                      className: css.propertiesContainer
                    })
              ]
            });
}

var make = UserCloudIaasServerProject_Details_RequiredProducts_Volume;

export {
  css ,
  make ,
}
/* css Not a pure module */
