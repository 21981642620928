// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserCloudIaasServerProject_Details_RequiredProductsScss from "./UserCloudIaasServerProject_Details_RequiredProducts.scss";
import * as UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription from "./UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserCloudIaasServerProject_Details_RequiredProductsScss;

function getContractLengthText(contractLength) {
  var years = contractLength / 12 | 0;
  return String(years) + (
          years > 1 ? " years" : " year"
        );
}

function UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance$CheckmarkableOptionsWithHeading(props) {
  var options = props.options;
  if (options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                            options: options,
                            cssStart: "StayShaded"
                          }),
                      className: css.checkboxes
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptionsWithHeading = {
  make: UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance$CheckmarkableOptionsWithHeading
};

function UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance(props) {
  var startDate = props.startDate;
  var contractLength = props.contractLength;
  var serverInstance = props.serverInstance;
  var image = serverInstance.image;
  var hypervisor = serverInstance.hypervisor;
  var db = serverInstance.database;
  var region = serverInstance.region;
  var types = serverInstance.types;
  var description = serverInstance.description;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Server Instances",
                      className: css.productName
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Quantity",
                                    value: String(serverInstance.vmNumber)
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "vCPUs",
                                    value: String(serverInstance.cpu)
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "RAM",
                                    value: serverInstance.ram
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Operating System",
                                    value: serverInstance.operatingSystem
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.stayLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Image",
                                    value: image !== undefined ? image : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Hypervisor",
                                    value: hypervisor !== undefined ? hypervisor : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Database",
                                    value: db !== undefined ? db : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Region",
                                    value: region !== undefined ? region : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.stayLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Start Date",
                                    value: startDate !== undefined ? $$Date.Naive.format(Caml_option.valFromOption(startDate), "dd MMM yyyy") : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Reservation Term",
                                    value: contractLength !== undefined ? getContractLengthText(contractLength) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Type",
                                    value: types !== undefined ? types : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance$CheckmarkableOptionsWithHeading, {
                              options: [
                                serverInstance.visibility,
                                serverInstance.availability
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Description",
                                    value: description !== undefined ? description : ""
                                  }),
                              className: Cx.cx([
                                    css.description,
                                    css.stayLight
                                  ])
                            })
                      ],
                      className: css.propertiesContainer
                    })
              ]
            });
}

var make = UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance;

export {
  css ,
  getContractLengthText ,
  CheckmarkableOptionsWithHeading ,
  make ,
}
/* css Not a pure module */
