// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              diskType: field.required("diskType", Json_Decode$JsonCombinators.string),
              diskSize: field.required("diskSize", Json_Decode$JsonCombinators.string),
              maxIops: field.required("maxIops", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              throughput: field.required("throughput", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              throughputUnit: field.required("throughputUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
