// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              vmNumber: field.required("vmNumber", Json_Decode$JsonCombinators.$$int),
              cpu: field.required("cpu", Json_Decode$JsonCombinators.$$int),
              ram: field.required("ram", Json_Decode$JsonCombinators.string),
              operatingSystem: field.required("operatingSystem", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              hypervisor: field.required("hypervisor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              database: field.required("database", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              region: field.required("region", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              types: field.required("type", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              visibility: field.required("visibility", Json_Decode$JsonCombinators.string),
              availability: field.required("availability", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
