// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserCloudIaasServerProject_Details_RequiredProductsScss from "./UserCloudIaasServerProject_Details_RequiredProducts.scss";
import * as UserCloudIaasServerProject_Details_RequiredProducts_Volume from "./UserCloudIaasServerProject_Details_RequiredProducts_Volume.res.js";
import * as UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance from "./UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance.res.js";

var css = UserCloudIaasServerProject_Details_RequiredProductsScss;

function panelHeading(configuration) {
  var instance = configuration.serverInstances;
  if (instance !== undefined) {
    return JsxRuntime.jsx("h4", {
                children: String(instance.vmNumber) + (" x " + (String(instance.cpu) + ((
                        instance.cpu > 1 ? " vCPUs, " : " vCPU, "
                      ) + (instance.ram + (" RAM, " + (instance.operatingSystem + (", " + (instance.visibility + (", " + instance.availability))))))))),
                className: css.panelHeading
              });
  } else {
    return JsxRuntime.jsx("h4", {
                children: ""
              });
  }
}

function UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("p", {
                        children: props.heading,
                        className: css.productName
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                              options: options
                            }),
                        className: css.checkboxes
                      })
                ],
                className: css.checkboxesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptionsWithHeading = {
  make: UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading
};

function UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var configuration = props.configuration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var instance = configuration.serverInstances;
  var volumes = configuration.volumes;
  var notes = configuration.additionalRequirements;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: panelHeading(configuration),
              isExpanded: isExpanded,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      instance !== undefined ? JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ServerInstance.make, {
                              serverInstance: instance,
                              contractLength: configuration.contractLength,
                              startDate: configuration.startDate
                            }) : "",
                      volumes !== undefined ? Belt_Array.mapWithIndex(volumes, (function (index, volume) {
                                return JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_Volume.make, {
                                            volume: volume,
                                            index: index
                                          }, String(index));
                              })) : null,
                      JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Features",
                            options: configuration.features
                          }),
                      JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Certifications",
                            options: configuration.certifications
                          }),
                      JsxRuntime.jsx(UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Billing Types",
                            options: configuration.billingTypes
                          }),
                      notes !== undefined ? JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Custom Note or Requirements",
                                      className: css.productName
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: notes,
                                      className: css.additionalNotes
                                    })
                              ],
                              className: css.propertiesContainer
                            }) : null
                    ],
                    className: css.panelBody
                  })
            });
}

var make = UserCloudIaasServerProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptionsWithHeading ,
  make ,
}
/* css Not a pure module */
